import request from '@/utils/request'

export default {

  clients(params) {
    return request({
      method: 'get',
      url: 'load-pick',
      params
    })
  },
  detail(params) {
    return request({
      method: 'get',
      url: 'load-pick/detail',
      params
    })
  },
  goods(data) {
    return request({
      method: 'put',
      url: 'load-pick/goods',
      data
    })
  },
  cancel(data) {
    return request({
      method: 'put',
      url: 'load-pick/cancel',
      data
    })
  },
  finish(data) {
    return request({
      method: 'put',
      url: 'load-pick/finish',
      data
    })
  },
  distributor() {
    return request({
      method: 'get',
      url: 'load-pick/distributor'
    })
  }

}

export function checkNew(params) {
  return request({
    method: 'get',
    url: 'load-pick/check-new',
    params
  })
}
