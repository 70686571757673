<template>
  <div class="load-pick-client-container">
    <my-nav-bar
      :title="$route.query.nickname"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <div class="load-pick-client-container__content">
        <van-empty v-if="showEmpty" description="暂无数据" />
        <van-list
          :loading="loading"
          :finished="finished"
          @load="getClients"
        >
          <van-cell
            v-for="(order, index) in orders"
            :key="index"
            class="load-pick-client-cell"
            center
            is-link
            :value="order.line.name + '线'"
            @click="toDetail(order.client)"
          >
            <template #title>
              <span>{{ order.client.name }}</span>
              <van-tag v-if="order.is_urgent" class="load-pick-client-container__content__cell__tag" type="danger">加急</van-tag>
            </template>
          </van-cell>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import loadPickApi from '@/api/load_pick'
import myNavBar from '@/components/my-nav-bar'
export default {
  name: 'LoadPickClient',
  components: { myNavBar },
  data() {
    return {
      orders: [],
      page: 0,
      showEmpty: false,
      loading: false,
      finished: false
    }
  },
  methods: {
    getClients() {
      this.page++
      const params = {
        page: this.page,
        limit: 10,
        delivery_id: this.$route.query.delivery_id
      }
      loadPickApi.clients(params).then(res => {
        this.orders = this.orders.concat(res.data.list)
        this.showEmpty = this.orders.length === 0
        this.loading = false
        this.finished = res.data.list.length === 0
      })
    },
    toDetail(client) {
      this.$router.push({
        path: '/load-pick-detail',
        query: {
          delivery_id: this.$route.query.delivery_id,
          client_name: client.name,
          client_id: client.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .load-pick-client-cell {
    height: 50px;
    .van-cell__title {
        flex: 4;
      }
    &__tag {
      margin-left: 5px;
    }
  }
</style>
